'use client';
import { FC, useEffect } from 'react';

import { authTokenAtom } from '@my-account/Atoms/AuthToken.atom';
import { useSetAtom } from 'jotai';
import { usePathname, useSearchParams } from 'next/navigation';

import { useRouter } from 'next/navigation';

export const WebLogic: FC = () => {
  const { replace } = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const setAuthToken = useSetAtom(authTokenAtom);

  useEffect(() => {
    const authToken = searchParams.get('authToken');

    if (authToken) {
      setAuthToken(authToken);
      console.log(`redirected from token ${authToken} for ${pathname}`);
      replace(pathname);
    }
  }, [pathname, searchParams]);

  useEffect(() => {
    // Check if the div with classname 'driftrock' exists in the DOM
    const driftrockDiv = document.querySelector('.driftrock');
    if (driftrockDiv) {
      // If it exists, remove the div
      driftrockDiv.remove();
    }
    if ((window as any).Driftrock) {
      (window as any).Driftrock.init({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        api_key: `${process.env.NEXT_PUBLIC_DRIFTROCK_KEY}`,
      });
    }
  }, [pathname]);

  return null;
};

WebLogic.displayName = 'WebLogic';
