import { KenticoImage } from '@core/Components/KenticoImage/KenticoImage';
import StarReviewRating from '@gds/ReviewRating/StarReviewRating';
import { Typography } from '@gds/Typography/Typography';

import { TrackedLink } from '../../TrackedLink/TrackedLink';

import styles from './ImageSubNavItem.module.css';

interface ImageSubNavItemProps {
  label: string;
  image: string[];
  imageAltTexts?: string[];
  link?: string;
  description?: string;
  date?: string;
  rating?: number | null;
  origin?: 'header' | 'footer';
  isNavMenuList?: boolean;
  navItemId?: string;
}

export const ImageSubNavItem: React.FC<ImageSubNavItemProps> = ({
  label,
  image,
  imageAltTexts,
  link,
  description,
  date,
  rating,
  origin,
  isNavMenuList,
  navItemId,
}) => (
  <TrackedLink
    className={styles.subNavImgLink}
    href={link}
    trackingObj={{ label: label, href: link }}
    origin={origin}
    isNavMenuList={isNavMenuList}
    navItemId={navItemId}
  >
    {image && image[0] && (
      <div className={styles.subNavImg}>
        <KenticoImage
          src={image[0]}
          alt={imageAltTexts?.[0] || label}
          fill
          style={{ objectFit: 'cover' }}
          sizes="25vw"
        />
      </div>
    )}
    <div className={styles.subNavImgContent}>
      <Typography variant="body2" className={styles.imageLinkLabel}>
        {label}
      </Typography>
      {rating && rating > 0 && (
        <div className={styles.subNavImgRating}>
          <StarReviewRating rating={rating} />
        </div>
      )}
      {date && (
        <Typography className={styles.subNavImgDate} variant="caption1" Component="div">
          {new Date(date).toLocaleDateString()}
        </Typography>
      )}
      {description && (
        <Typography variant="caption2" Component="div" className={styles.subNavImgDescription}>
          {description}
        </Typography>
      )}
    </div>
  </TrackedLink>
);
