'use client';
import { useState } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import { useSetAtom } from 'jotai';

import { useModal } from '@core/Components/Modal/UseModal';
import { sanitizePath } from '@mobile-app/Constants/NativeScreenTypeMap';
import { ctaClickedToEvent } from '@tracking/Schemas/CtaClicked/CtaClickedToEvent';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { headerClickTracking } from '../../Constants/Header.constants';
import { footerClickTracking } from '../../Constants/Footer.constants';
import {
  FooterTrackingObj,
  HeaderTrackingObj,
  TrackedLinkProps,
} from '../../Entities/TrackedLink.entity';
import { navTrayOpenAtom } from '../../Atoms/NavTrayOpenAtom';
import { nlsToggleAtom } from '@core/Components/NaturalLanguageSearch/NaturalLanguageSearchToggle.atom';
import { navHoveredAtom } from '../../Atoms/NavHoveredAtom';

export const trackEvent = (track?: {
  fn: ((trackingObj: HeaderTrackingObj | FooterTrackingObj) => void) | undefined;
  obj: Partial<HeaderTrackingObj | FooterTrackingObj>;
  origin?: 'header' | 'footer';
}) => {
  if (track && typeof track?.fn === 'function') {
    track.fn({
      ...(track.origin === 'footer' ? footerClickTracking : headerClickTracking),
      ...track.obj,
    } as HeaderTrackingObj | FooterTrackingObj);
  }
};

export const itemOnClick = (
  track?: {
    fn: ((trackingObj: HeaderTrackingObj | FooterTrackingObj) => void) | undefined;
    obj: Partial<HeaderTrackingObj | FooterTrackingObj>;
    origin?: 'header' | 'footer';
  },
  onClick?: () => void,
) => {
  trackEvent(track);
  if (typeof onClick === 'function') onClick();
};

export const trackingFunction = ({
  category,
  label,
  href,
}: HeaderTrackingObj | FooterTrackingObj) => {
  trackCustomEvent({
    event: ctaClickedToEvent({ ctaType: category, ctaWording: label, targetUrl: href || '' }),
    context: [],
  });
};

export const TrackedLink = ({ trackingObj, children, ...rest }: TrackedLinkProps) => {
  const pathname = usePathname();
  const { closeModal } = useModal();
  const [shouldPrefetch, setShouldPrefetch] = useState(false);

  const setIsNavTrayOpen = useSetAtom(navTrayOpenAtom);
  const setToggleSearchBar = useSetAtom(nlsToggleAtom);
  const setIsNavHovered = useSetAtom(navHoveredAtom(rest?.navItemId));

  const handleClick = () => {
    setIsNavTrayOpen(false);
    setToggleSearchBar(false);
    setIsNavHovered(false);
    if (rest?.isNavMenuList) {
      closeModal();
    }

    if (pathname !== rest.href) {
      itemOnClick({
        fn: trackingFunction,
        obj: trackingObj,
        origin: rest?.origin,
      });
    }
  };
  const sanitizedHref = sanitizePath(rest?.href);

  return (
    <Link
      href={sanitizedHref ?? '/'}
      key={rest?.key}
      className={rest?.className}
      onClick={handleClick}
      prefetch={shouldPrefetch}
      onMouseOver={() => setShouldPrefetch(true)}
      aria-label={rest?.ariaLabel}
    >
      {children}
    </Link>
  );
};
