'use client';

import { useAtomValue } from 'jotai';
import { favouritesAtom } from '@core/Atoms/Favourites/Favourites.atom';
import { MAX_FAVES_DISPLAY_NO } from '@gds/Header/Constants/Header.constants';
import { HeartDefault } from '@gds/Icons/Paths/HeartDefault';
import { HeartHover } from '@gds/Icons/Paths/HeartHover';
import { Typography } from '@gds/Typography/Typography';

import { TrackedLink } from '../../TrackedLink/TrackedLink';

import styles from '../NavButtons.module.css';

export const FavouritesButton = ({
  baseUrl,
  isFooterEnabled,
}: {
  baseUrl: string;
  isFooterEnabled?: boolean;
}) => {
  const favs = useAtomValue(favouritesAtom);
  const favsCount = favs?.length ?? 0;

  const favItems = {
    favoritesNumber: favsCount,
    href: `${baseUrl}/favourites`,
    label: 'Favourites',
  };
  return (
    <TrackedLink
      href={favItems.href}
      className={`${isFooterEnabled ? styles.horizontalNavOnly : ''} ${styles.item} ${
        styles.faves
      } `}
      trackingObj={{ label: favItems!.label, href: favItems!.href }}
    >
      <span className={styles.iconWrapper}>
        <HeartDefault className={styles.heartDefault} />
        <HeartHover className={styles.heartHover} />
      </span>
      <Typography
        variant="caption4"
        Component="span"
        aria-label={favItems.label}
        className={`${styles.counter} ${favsCount > 0 ? styles.active : ''}`}
      >
        {favsCount > MAX_FAVES_DISPLAY_NO ? MAX_FAVES_DISPLAY_NO : favsCount}
      </Typography>
    </TrackedLink>
  );
};
